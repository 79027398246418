<script context="module">
  export async function preload(page, session) {
    const res = await this.fetch(`api/sections/root`);
    const data = await res.json();

    let viewData

    if (page.query.view) {
        const viewRes = (await this.fetch(`api/project/${page.query.view}`))

        if (viewRes.ok) {
          viewData = await viewRes.json()
        }
      }

    return {
      links: data.sections,
      seo: data?.seo || {},
      view: viewData
    }
  }
</script>

<script>
  import { onMount } from 'svelte';
  import { goto, stores } from '@sapper/app'
  import SectionLink from '../components/SectionsList/SectionLink.svelte'
  import Modal from '../components/Modal/index.svelte'
  import ProjectContent from '../components/ProjectContent.svelte'
  import ArrowBLock from '../components/KeyboardNav/ArrowBlock.svelte'

  let { page } = stores()

  export let links
  export let seo
  export let view

  let isArrowVisible = false

  onMount(() => {
    if (!sessionStorage.getItem('arrow-shown') === true) {
      isArrowVisible = true
      sessionStorage.setItem('arrow-shown', true)

      setTimeout(() => {
        isArrowVisible = false
      }, 4500);
    }
  })

  function closeModal () {
    const q = {...$page.query}
    delete q.view
    goto(`${$page.path}`, { replaceState: false, noscroll: true })
  }
</script>

<svelte:head>
  <title>{seo.title || 'KR Digital'}</title>
  <meta property="og:title" content={seo.title || 'KR Digital'} />
  <meta name="description" content={seo.description || ''} />
  <meta property="og:description" content={seo.description || ''} />
  <meta name="keywords" content={seo.keywords || ''} />
</svelte:head>

<main class="main">
  <div class="wrapper">
    <img  class="logo" src="/images/kr-logo-lines.png" width="102" height="50" alt="kr.digital">
    <span class="label">Аутсорсинг с глубоким погружением</span>
  </div>
    {#each links as d, i (d.id)}
      <SectionLink delay={i * 30} href="{d.url || '/section/'+d.id}">{d.title}&nbsp;<span class="linkArr">→</span></SectionLink>
    {/each}
  {#if isArrowVisible}
    <ArrowBLock />
  {/if}
  {#if view}
  <Modal on:closeClick={closeModal}>
    <ProjectContent data={view} on:closeClick={closeModal} />
  </Modal>
  {/if}
</main>



<style>.main{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;width:100%}.logo{margin-right:10px}.label{font-weight:500;font-size:13px;line-height:1.615;color:var(--strong-muted-text-color)}.wrapper{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:end;-ms-flex-align:end;align-items:flex-end;margin-top:-17px;margin-bottom:23px}@media (max-width:1024px){.logo{height:30px;width:auto}}@media (max-width:600px){.logo{margin-bottom:5px}}</style>
