<script>
import { fade } from 'svelte/transition';


  export let arrowArray = ["↑", "←", "↓", "→"];
</script>

<div 
  class="arrow-block"
  in:fade={{ duration: 100 }}
  out:fade={{ duration: 200 }}
>
  {#each arrowArray as arrow, i}
    <span
      class="arrow"
      style="animation-delay: {i === 0 ? 200 : (arrowArray.length - i) * 200 + 200}ms;"
    >
      {arrow}
    </span>
  {/each}
</div>

<style>.arrow-block{display:none}@media (min-width:767px){.arrow-block{position:fixed;bottom:10%;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);display:grid;grid-template-columns:repeat(3,1fr);grid-auto-flow:dense;grid-gap:4px;gap:4px}.arrow{width:30px;height:30px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;border:2px solid #fff;border-radius:8px;-webkit-animation:blinking .7s ease-in-out 3;animation:blinking .7s ease-in-out 3;opacity:.5}.arrow:first-child{grid-column:1/-1;margin:0 auto;-webkit-animation:blinking .7s ease-in-out 4;animation:blinking .7s ease-in-out 4}@-webkit-keyframes blinking{0%,100%{background-color:transparent}50%{background-color:#3be8ba}}@keyframes blinking{0%,100%{background-color:transparent}50%{background-color:#3be8ba}}}</style>
